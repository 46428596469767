<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:34:29
 * @LastEditTime: 2023-07-06 13:51
 * @Descripttion: 课件库
-->
<style lang="scss" scoped>
.courseware {
    @include innerPage(28px 32px 48px 15px);
    @include pageTitle(0);
    @include pageTab;
    @include pageFoot;

    &-container {
        width: 100%;
        height: calc(100% - 137px);
        box-sizing: border-box;
        padding-top: 38px;

        .school-search {
            height: 46px;
            box-sizing: border-box;
            padding: 0 24px 0 36px;
            background: #f1f2f8;
            border-radius: 10px;
            color: #a9a9a9;
            margin-left: 12px;
            cursor: pointer;
            margin-bottom: 32px;
            @include flexBox;
            display: inline-flex;

            .iconfont {
                color: #555;
            }

            .search-result {
                color: #272727;
                font-size: 16px;
                @include flexBox;
            }

            .search-result .iconfont {
                font-size: 18px;
                margin-right: 4px;
                padding-left: 12px;
            }

            .ml {
                margin-left: 30px;
            }

            &-wrapper {
                margin-bottom: 32px;
                margin-left: 12px;
                @include flexBox;

                .el-button {
                    margin-left: 22px;
                }
            }
        }
    }

    &-data {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 20px;

        &-wrapper {
            width: 100%;
            height: calc(100% - 74px);
            position: relative;

            &.school {
                height: calc(100% - 152px);
            }
        }

    }

    &-list {
        width: 100%;
        height: 100%;
        padding: 0 16px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        box-sizing: border-box;
        @include flexBox(space-between, flex-start);

        &::after {
            content: '';
            width: calc((100% - 40px) / 3);
        }

        &--public {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
            overflow: hidden;
            overflow-y: auto;
            @include flexBox(space-between, flex-start);

            .courseware {
                &-item {
                    width: calc((100% - 60px) / 4);
                    height: 364px;
                    border-radius: 10px;
                    overflow: hidden;
                    background: #fff;
                    cursor: default;

                    .el-image {
                        width: 100%;
                        height: 218px;
                    }

                    .from {
                        width: 338px;
                        height: 86px;
                        margin: 0 auto;
                        flex-direction: column;
                        line-height: 24px;
                        border-bottom: 1px solid #ececec;
                        @include flexBox(center, flex-start);

                        h5 {
                            color: #3c3b3b;
                        }

                        p {
                            color: #787878;
                            margin-top: 6px;
                        }
                    }

                    .target {
                        width: 338px;
                        height: 58px;
                        margin: 0 auto;
                        box-sizing: border-box;
                        padding: 15px;
                        @include flexBox(space-between);

                        .person {
                            color: #7a7a7a;
                        }

                        .pf_bold {
                            font-size: 22px;
                            color: #4d4d4d;
                            margin-right: 4px;
                        }

                        .link {
                            color: #6340c8;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }

                    &:hover {
                        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15), 5px 5px 27px -10px rgba(108, 78, 203, 0.15);
                    }
                }
            }

            .empty {
                width: 375px;
            }
        }
    }

    &-item {
        width: calc((100% - 40px) / 3);
        height: 246px;
        margin-bottom: 34px;
        position: relative;
        cursor: pointer;

        .info-data {
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            flex-direction: column;
            @include flexBox(flex-start);

            h4 {
                width: 100%;
                height: 76px;
                background: #f6f7fc;
                box-sizing: border-box;
                padding: 0 40px;
                color: #474747;
                flex-shrink: 0;
                position: relative;
                @include flexBox;

                .iconfont-color {
                    font-size: 34px;
                    margin-right: 10px;
                }

                &::after {
                    content: '';
                    width: 62px;
                    height: 30px;
                    line-height: 30px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    border-radius: 0 0 0 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 14px;
                    color: #fff;
                    transform: scaleY(0);
                    font-family: 'MicrosoftYaHei';
                }
            }

            .desc {
                width: calc(100% - 80px);
                margin: 22px 0 30px;
                line-height: 26px;
                color: #4a4a4a;
                @include ellipsisMultiline;
            }

            .info-bt {
                width: calc(100% - 80px);
                height: 68px;
                border-top: 1px solid #e9e9e9;
                margin-top: auto;
                line-height: 24px;
                flex-shrink: 0;
                @include flexBox(space-between);

                .name {
                    color: #353535;
                }

                .time {
                    color: #949495;
                }

                .collect {
                    color: #4a4a4a;
                    padding: 0;
                    height: 38px;

                    .button-container {
                        @include flexBox;
                    }

                    .iconfont {
                        font-size: 28px;
                        color: #d6d8e1;
                    }

                    &.checked {
                        .iconfont {
                            color: #feaf2f;
                        }
                    }
                }

                .price {
                    margin-left: auto;
                    margin-right: 12px;
                    color: #f66478;
                    padding-top: 5px;

                    .pf_bold {
                        font-size: 24px;
                        margin-left: 4px;
                    }
                }
            }
        }

        &:hover {
            z-index: 1;

            .info-data {
                box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15), 5px 5px 27px -10px rgba(108, 78, 203, 0.15);
            }
        }

        &.reviewed .info-data,
        &.passed .info-data,
        &.failed .info-data {
            h4:after {
                transform: scaleY(1);
            }
        }

        &.reviewed .info-data h4:after {
            content: '待审核';
            background: #feaf2f;
        }

        &.passed .info-data h4:after {
            content: '已共享';
            background: #2ac293;
        }

        &.failed .info-data h4:after {
            content: '未通过';
            background: #ee4f65;
        }

    }

    .no-data {
        background: #fff;
        border-radius: 10px;
    }
}

.dialog-search {
    box-sizing: border-box;
    padding: 38px 0;

    .grade {
        width: 726px;
        margin: 0 auto 44px;
        @include flexBox(space-between);

        .el-select {
            width: 360px;
        }
    }

    .curriculum {
        height: 50vh;
        max-height: 530px;
        @include flexBox(space-between);

        .edition,
        .edition-no,
        .chapter,
        .sections {
            width: calc((100% - 6px) / 4);
            height: 100%;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 10px 6px 14px 30px;

            h5 {
                height: 46px;
                box-sizing: border-box;
                padding-right: 18px;
                margin-bottom: 16px;
                @include flexBox(space-between);

                span {
                    flex-shrink: 0;
                    margin-right: 10px;
                }
            }

            ::v-deep {
                .el-input {
                    flex-grow: 1;

                    &__inner {
                        background: #fff;
                        padding-right: 60px;
                    }

                    &__suffix {
                        .iconfont {
                            color: #6c4ecb;
                        }

                        .el-input__clear {
                            margin-right: -50px;
                            position: relative;

                            &::after {
                                content: '';
                                width: 1px;
                                height: 10px;
                                left: -2px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                background: #b6b6b6;
                            }
                        }
                    }
                }
            }
        }

        .edition {
            background: rgba($color: #d83131, $alpha: 0.05);
        }

        .edition-no {
            background: rgba($color: #2ac293, $alpha: 0.05);
        }

        .chapter {
            background: rgba($color: #feaf2f, $alpha: 0.05);
        }

        .sections {
            background: rgba($color: #6c4ecb, $alpha: 0.05);
        }

        &-item {
            height: calc(100% - 62px);
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;

            li {
                line-height: 42px;
                margin-bottom: 20px;
                font-size: 16px;
                color: #383838;

                &:last-child {
                    margin-bottom: 0;
                }

                &.current,
                &:not(.current):not(.not-data):hover {
                    color: #6c4ecb;
                    cursor: pointer;
                }

                &.not-data {
                    height: 100%;
                    flex-direction: column;
                    @include flexBox(center);

                    img {
                        width: 125px;
                    }

                    p {
                        color: #b6b6b6;
                        margin-top: 6px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.large-dialog{
//   max-width: 1280px;
  min-width: 1000px;
  width: 70%;
}
</style>

<template>
    <section class="courseware">
        <div class="page-title">
            <h3>资源库</h3>
            <div class="search-box">
                <el-input v-model.trim="searchKey" maxlength="100" placeholder="搜索资源名称" clearable @clear="handleSearch">
                    <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
                <el-button type="custom_primary" size="medium" @click="handleSearch">搜索</el-button>
            </div>
        </div>
        <!-- tab标签导航 -->
        <div class="page-tab">
            <ul class="tabs bold">
                <li class="tab" :class="{ current: currentTab == item.value }" v-for="item in tabs" :key="item.value"
                    @click="changeTab(item.value)">
                    {{ item.label }}
                </li>
            </ul>
        </div>
        <!-- 课件内容 -->
        <div class="courseware-container">
            <!-- 校本库筛选 -->
            <div class="school-search" v-if="currentTab == 1">
                <p class="search-result" v-html="searchTitle"></p>
                <p @click="showDialog" :class="{ ml: searchTitle }">
                    <span>筛选</span>
                    <span class="iconfont">&#xe600;</span>
                </p>
            </div>
            <div class="courseware-data-wrapper" :class="{ 'no-data': $isEmpty(tableData), school: currentTab == 1 }"
                v-loading="dataLoad">
                <!-- 列表 -->
                <div class="courseware-data" :class="{ public: currentTab == 2 }" v-show="!$isEmpty(tableData)">
                    <!-- 校本 -->
                    <ul class="courseware-list" v-if="currentTab == 1">
                        <li class="courseware-item" v-for="item in tableData" :key="item.sccou_id"
                            :class="{ reviewed: item.sccou_sharestatus == 20, passed: item.sccou_sharestatus == 30, failed: item.sccou_sharestatus == 40 }"
                            @click="targetDetail(item.sccou_id)">
                            <div class="info-data">
                                <h4 class="bold">
                                    <span class="iconfont-color" v-html="fileType(item.sccou_type)"></span>
                                    <p class="line-text--1st">{{ item.sccou_title }}</p>
                                </h4>
                                <p class="desc light">
                                    {{ item.sccou_remark }}
                                </p>
                                <div class="info-bt">
                                    <p>
                                        <span class="name">{{ item.teuse_name }}</span>
                                        <br>
                                        <span class="time pf">
                                            {{ item.create_time * 1000 | formatTime('YYYY/MM/DD') }}
                                        </span>
                                    </p>
                                    <el-button type="custom_primary" size="small"
                                        v-if="item.sccou_sharestatus != 20 && item.sccou_sharestatus != 30"
                                        @click.stop="shareFile(item.sccou_id)">
                                        共享
                                    </el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 平台 -->
                    <ul class="courseware-list" v-if="currentTab == 2">
                        <li class="courseware-item" v-for="item in tableData" :key="item.plcou_id"
                            @click="targetDetail(item.plcou_id)">
                            <div class="info-data">
                                <h4 class="bold">
                                    <span class="iconfont-color" v-html="fileType(item.plcou_type)"></span>
                                    <p class="line-text--1st">{{ item.plcou_title }}</p>
                                </h4>
                                <p class="desc light">
                                    {{ item.plcou_remark }}
                                </p>
                            </div>
                        </li>
                    </ul>
                    <!-- 公共 -->
                    <ul class="courseware-list--public" v-if="currentTab == 3">
                        <li class="courseware-item" v-for="item in tableData" :key="item.plcou_pub_id">
                            <el-image fit="scale-down" :src="formatfile(item.plcou_pub_image)" />
                            <div class="from">
                                <h5 class="bold">{{ item.plcou_pub_title }}</h5>
                                <p class="link">{{ item.plcou_pub_url }}</p>
                            </div>
                            <div class="target">
                                <p class="person">
                                    <span class="pf_bold">{{ item.plcou_pub_num }}</span><span>人已使用</span>
                                </p>
                                <span class="link"
                                    @click.stop="targetPublicCourseware(item.plcou_pub_url, item.plcou_pub_id)">
                                    立即访问&gt;
                                </span>
                            </div>
                        </li>
                        <template v-if="tableData.length % 4 > 0">
                            <li class="empty" v-for="item in (4 - tableData.length % 4)" :key="item" />
                        </template>
                    </ul>
                </div>
                <div class="no-data--empty" v-if="$isEmpty(tableData) && !dataLoad">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无课件资源哦~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize"
                    @pageChange="flippingPage" />
            </div>
        </div>
        <!-- 数据筛选弹窗 -->
        <el-dialog title="筛选内容" width="1400px" :visible.sync="searchDialog" custom-class="large-dialog" @close="hideDialog">
            <div class="dialog-search">
                <div class="curriculum">
                    <div class="edition">
                        <h5 class="bold">年级</h5>
                        <ul class="curriculum-item">
                            <li :class="{ current: searchChoose.grade && searchChoose.grade.id == item.grade_id }"
                                v-for="item in gradeList" :key="item.grade_id"
                                @click="chooseSearchKey('grade', item.grade_id, item.grade_name)">
                                {{ item.grade_name }}
                            </li>
                        </ul>
                    </div>
                    <div class="edition-no">
                        <h5 class="bold">科目</h5>
                        <ul class="curriculum-item">
                            <li :class="{ current: searchChoose.sysub && searchChoose.sysub.id == item.sysub_id }"
                                v-for="item in subjectList" :key="item.sysub_id"
                                @click="chooseSearchKey('sysub', item.sysub_id, item.sysub_name)">
                                {{ item.sysub_name }}
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sections">
                        <h5>
                            <span class="bold">教材</span>
                            <el-input
                                v-model.trim="sccouKey" maxlength="100" placeholder="请输入关键字搜索" clearable @change="get_school_course_list()" @clear="get_school_course_list()" >
                                <span slot="suffix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </h5>
                        <ul class="curriculum-item">
                        <template v-if="sccouData && sccouData.length">
                            <li :class="{ current:searchChoose.sccou && searchChoose.sccou.id == item.sccou_id }" v-for="item in sccouData"  :key="item.sysub_id" @click=" chooseSearchKey('sccou',item.sccou_id, item.title ) " >
                                {{ item.title }}
                            </li>
                        </template>
                        <li class="not-data" v-else>
                            <img src="@assets/images/no-data2.png" alt />
                            <p>暂无选项哦~</p>
                        </li>
                        </ul>
                    </div>
                    <div class="chapter">
                        <h5>
                            <span class="bold">章</span>
                            <el-input v-model.trim="chapterKey" maxlength="100" placeholder="请输入关键字搜索" clearable
                                @change="getChapters(1)" @clear="getChapters(1)">
                                <span slot="suffix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </h5>
                        <ul class="curriculum-item">
                            <template v-if="chapterData && chapterData.length">
                                <li :class="{ current: searchChoose.chaper && searchChoose.chaper.id == item.sccou_cha_id }"
                                    v-for="item in chapterData" :key="item.sysub_id"
                                    @click="chooseSearchKey('chaper', item.sccou_cha_id, item.sccou_cha_title)">
                                    {{ item.sccou_cha_title }}
                                </li>
                            </template>
                            <li class="not-data" v-else>
                                <img src="@assets/images/no-data2.png" alt="">
                                <p>暂无选项哦~</p>
                            </li>
                        </ul>
                    </div>
                    <div class="sections">
                        <h5>
                            <span class="bold">节</span>
                            <el-input v-model.trim="jointKey" maxlength="100" placeholder="请输入关键字搜索" clearable
                                @change="getChapters(2)" @clear="getChapters(2)">
                                <span slot="suffix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </h5>
                        <ul class="curriculum-item">
                            <template v-if="jointData && jointData.length">
                                <li :class="{ current: searchChoose.joint && searchChoose.joint.id == item.sccou_cha_id }"
                                    v-for="item in jointData" :key="item.sysub_id"
                                    @click="chooseSearchKey('joint', item.sccou_cha_id, item.sccou_cha_title)">
                                    {{ item.sccou_cha_title }}
                                </li>
                            </template>
                            <li class="not-data" v-else>
                                <img src="@assets/images/no-data2.png" alt="">
                                <p>暂无选项哦~</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div slot="footer" class="foot-center">
                <el-button type="custom_primary" size="small" @click="handleSearch">确 定</el-button>
                <el-button type="custom_warning" size="small" @click="resetSearchChoose">重 置</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $getCourseware, $shareCourseware, $publicCoursewareAddView } from "@api/resources"
import { $getSubjectChapters,$get_school_course_list} from "@api/common"
import { formatFileType, formatFile } from "@utils"
import { mapState } from "vuex"
import customPagination from "@comp/customPagination"
export default {
    name: 'resources_courseware',
    components: { customPagination },
    computed: {
        ...mapState('common', ['gradeList', 'subjectList']),
        fileType() {
            return function (val) {
                return formatFileType(val);
            }
        },
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            tabs: [{
                label: '校本资源',
                value: 1
            }, {
                label: '学校共享资源',
                value: 2
            }, {
                label: '公共资源',
                value: 3
            }], // 标签页分类
            currentTab: 1, // 当前标签页
            /* 筛选课件 */
            searchDialog: false, // 显示筛选弹窗
            searchTitle: '', // 筛选章节文本
            searchChoose: {}, // 筛选选中数据
            searchResult: {}, // 筛选数据集合
            sccouData: [], // 教材
            sccouKey:"",//教材（关键字）
            chapterData: [], // 章
            chapterKey: '', // 筛选章（关键字）
            jointData: [], // 节
            jointKey: '', // 筛选节（关键字）
            searchKey: '',
            tableData: [], // 课件列表数据
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 12,
                total: 0
            },
            dataLoad: false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取课件列表 */
        async getData() {
            this.dataLoad = true;
            let { grade, sysub, chaper, joint } = this.searchResult;
            let params = {
                query_word: this.searchKey,
                pageindex: this.tablePage.pageIndex,
            }
            if (this.currentTab == 1 && grade) params.tecou_grade = grade.id;
            if (this.currentTab == 1 && sysub) params.sysub_id = sysub.id;
            if (this.currentTab == 1 && chaper) params.sccou_cha_id = chaper.id;
            if (this.currentTab == 1 && joint) params.sccou_cha_id = joint.id;

            let { data: res } = await $getCourseware(this.currentTab, params, this.tablePage.pageSize);

            this.dataLoad = false;

            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /**
         * 资源共享
         * @param {number} id 课件id
         */
        shareFile(id) {
            this.$msgbox({
                title: "提示",
                message: "确认将此课件资源共享至平台？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $shareCourseware(id);
                if (res) {
                    this.$message({
                        type: "success",
                        duration: 1500,
                        message: "申请已提交，等待平台审核",
                    });
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /**
         * 公共资源访问量增加
         * @param {string} url 公共课件地址
         * @param {numer} id 公共课件id
         */
        async targetPublicCourseware(url, id) {
            let res = await $publicCoursewareAddView(id);
            if (res) return window.open(url, '_blank')
        },
        
        /**
         * 筛选
         * ---- 获取学校教材
         * @param {int}  grade 年级  sysub_id 科目  chapter_word 章节关键字 is_trees 是否返回树状1是 2否
         */
        async get_school_course_list() {
        let { grade, sysub } = this.searchChoose;
        if (!grade || !sysub) return;
        delete this.searchChoose.sccou; // 清除选中的节id
        let params = {
            chapter_word:this.sccouKey,
            grade: grade.id,
            sysub_id: sysub.id,
        };
        let { data } = await $get_school_course_list(params);
        this.sccouData = data
        this.$forceUpdate();
        },
        /**
         * 获取章节数据
         * @param {int} type 数据类型 1：章   2：节
         */
        async getChapters(type) {
            let { grade, sysub, chaper, sccou } = this.searchChoose
            if (!grade || !sysub || !sccou) return;
            delete this.searchChoose.joint; // 清除选中的节id
            if(this.$isEmpty(chaper) && type == 2) return;
            let params = {
                chapter_word: type == 1 && this.chapterKey || this.jointKey,
                grade: grade.id,
                sysub_id: sysub.id,
                sccou_id:sccou.id
            }
            /* 若选中章触发，传递章id获取节数据 */
            if (chaper && type != 1) params.sccou_cha_pid = chaper.id;
            let { data } = await $getSubjectChapters(params);
            /* 章 */
            if (type == 1) {
                this.chapterData = data[0];
                /* 章数据更新，清除选中章及节数据 */
                delete this.searchChoose.chaper;
                this.jointData = [];
                this.jointKey = ''
            }
            /* 节 */
            else {
                this.jointData = data[0]
            }
            this.$forceUpdate();
        },
        /** 获取章节数据(树) */
        async getChaptersTree() {
            let { tecou_grade: grade, sysub_id } = this.uploadForm;
            if (grade && sysub_id) {
                let params = { grade, sysub_id, is_trees: 1 }
                let { data } = await $getSubjectChapters(params);
                data = data[0];
                data.forEach((item) => {
                    if (this.$isEmpty(item.children)) delete item.children;
                    else {
                        item.children.forEach(citem => {
                            if (this.$isEmpty(citem.children)) delete citem.children;
                        })
                    }
                });
                this.chaptersTree = data;
                this.$forceUpdate();
            }
        },
        /**
         * 筛选节点选中
         * @param {string} el 筛选数据key
         * @param {number} val 筛选数据value
         */
        chooseSearchKey(el, id, name) {
            this.searchChoose[el] = { id, name };
            /* 根据选中数据类型，清空对应子级数据 */
            switch (el) {
                case 'grade':
                    delete this.searchChoose.sysub;
                    delete this.searchChoose.sccou;//教材
                    delete this.searchChoose.chaper;
                    delete this.searchChoose.joint;
                    this.chapterData = [];
                    this.sccouData = [];
                    this.jointData = [];
                    this.sccouKey = "";
                    this.chapterKey = '';
                    this.jointKey = ''
                    break;
                case 'sysub':
                    delete this.searchChoose.chaper;
                    delete this.searchChoose.sccou;
                    delete this.searchChoose.joint;
                    this.chapterData = [];
                    this.sccouData = [];
                    this.jointData = [];
                    this.sccouKey = "";
                    this.chapterKey = '';
                    this.jointKey = ''
                    break;
                case "sccou"://选中教材清理数据
                    delete this.searchChoose.chaper;
                    delete this.searchChoose.joint;
                    this.chapterData = [];
                    this.jointData = [];
                    this.chapterKey = "";
                    this.jointKey = "";
                    break;
                case 'chaper':
                    delete this.searchChoose.joint;
                    this.jointData = [];
                    this.jointKey = ''
                    break;
                default:
                    break;
            }
             /* 选中科目时获取教材的时候请求*/
            if (this.searchChoose.grade && this.searchChoose.sysub && el == "sysub") {
                // 请求教材列表
                this.get_school_course_list();
            }
            /* 选中科目时，获取章节数据 不等于教材和不等于节的时候请求*/
            if (this.searchChoose.grade && this.searchChoose.sysub && this.searchChoose.sccou && el != 'joint') {
                let type = (el == "chaper" && 2) || 1;
                // 请求章节列表
                this.getChapters(type);
            }
            this.$forceUpdate();
        },
        /** 筛选数据 */
        handleSearch() {
            this.searchResult = { ...this.searchChoose };
            let { grade, sysub,sccou, chaper, joint } = this.searchChoose;
            this.searchChoose = {};
            this.searchTitle = `${grade && `<span class="iconfont">&#xe607;</span>${grade.name}` || ''}${sysub && sysub.name || ''}${sccou && `：${sccou.name}` || ''}${chaper && `：${chaper.name}` || ''}${joint && `&nbsp;-&nbsp;${joint.name}` || ''}`
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.hideDialog();
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 切换标签页 */
        changeTab(val) {
            if(this.currentTab == val) return;
            this.currentTab = val;
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.searchResult = {};
            this.searchChoose = {};
            this.searchTitle = this.searchKey = '';
            this.getData();
        },
        /** 重置选中筛选节点 */
        resetSearchChoose() {
            this.searchChoose = {};
            this.chapterData = [];
            this.chapterKey = '';
            this.jointData = [];
            this.jointKey = '';
        },
        /** 打开弹窗 */
        showDialog() {
            this.searchDialog = true;
            let { grade, sysub, chaper, joint } = this.searchResult;
            if (grade) {
                this.chooseSearchKey('grade', grade.id, grade.name);
            }
            if (sysub) {
                this.chooseSearchKey('sysub', sysub.id, sysub.name);
            }
            if (chaper) {
                this.chooseSearchKey('chaper', chaper.id, chaper.name);
            }
            if (joint) {
                this.chooseSearchKey('joint', joint.id, joint.name);
            }
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.searchDialog = false;
            this.resetSearchChoose();
        },
        /**
         * 跳转详情
         * @param {number} id 课件资源id
         */
        targetDetail(id) {
            this.$router.push({
                name: 'RESOURCES_COURSEWARE_DETAIL',
                params: { id, type: this.currentTab }
            })
        },
    }
}
</script>